import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import { useSiteContent } from "../hooks/use-site-content"
import Img from "gatsby-image/index"
import Resources from "../../resources/mlp-resources.json"
import BackgroundImage from "gatsby-background-image/index"
import "../styles/global.css"

const NotFoundWrapper = styled.div`
  
`
const NotFoundContainer = styled.div`
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-content: center;
  
  .container {
    margin: 25px auto;
    max-width: 1280px;
    padding: 0.5rem 0;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    .container {
      padding: 0 30px;
      max-width: 100%;
    }
    
    height: calc(100vh - 200px);
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .container {
      padding: 0 30px;
      max-width: 720px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1279px) {
    .container {
      padding: 0 30px;
      max-width: 960px;
    }
  }

  /* Extra large devices (large laptops and desktops, 1280px and up) */
  @media only screen and (min-width: 1280px) {
    .container {
      padding: 0 30px;
      max-width: 1140px;
    }
  }
`

const InnerNotFoundContainer = styled.div`
  display: grid;
  align-self: center;
  grid-template-columns: 50fr 1fr 50fr;
  max-height: 500px;  
  
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

const NotFoundTitle = styled.label`
  font-family: var(--font-bold);
  font-size: ${Resources.areas_esp.title.size};
  text-transform: uppercase;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.areas_esp.title.size_small};
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.areas_esp.title.size_med};
  }
`

const CenterDiv = styled.div`
  align-self: center;
  justify-self: ${props => props.position};
  width: auto; 
  
  &.padding-right {
    padding-right: 80px;
  }
  
  @media only screen and (max-width: 767px) {
   &.no-mobile {
      display: none !important;
    }
    &.padding-right {
    padding-right: 0;
  }
  }
`

const Line = styled.div`
  width: 1px;
  height: 350px;
  background-color: var(--primary-color);
  @media only screen and (max-width: 767px) {
    &.no-mobile {
      display: none !important;
    }
  }
`


const NotFoundPage = () => {
  const { not_found: { text } } = useSiteContent()
  return (
    <StaticQuery
      query={graphql`
          query {
            bg: file(name: { eq: "404_img_responsive" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            img: file(name: { eq: "404_img" }) {
                childImageSharp {
                  fluid(quality: 100, maxHeight: 350) {
                  ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
          }
        `}
      render={data => (
        <NotFoundWrapper>
          <BackgroundImage
            Tag={"div"}
            className={"bg-image-404"}
            fluid={data.bg.childImageSharp.fluid}
          />
          <NotFoundContainer>
            <InnerNotFoundContainer className={"container"}>
              <CenterDiv position={"left"} className={"padding-right"}>
                <NotFoundTitle>{Resources.not_found.title}</NotFoundTitle>
                <p dangerouslySetInnerHTML={{ __html: text }}/>
              </CenterDiv>
              <Line className={"no-mobile"}/>
              <CenterDiv position={"right"} className={"no-mobile"}>
                <Img fluid={data.img.childImageSharp.fluid}
                     className="img"
                     style={{ height: 350, width: 350 }}
                     imgStyle={{ objectFit: "contain", width: "auto", height: 350 }}/>
              </CenterDiv>
            </InnerNotFoundContainer>
          </NotFoundContainer>
        </NotFoundWrapper>
      )
      }/>
  )
}

export default NotFoundPage
